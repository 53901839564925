.TableArticles {

    .containerr {
        @media (max-width: 280px) {
            padding: 100px 10px 0 10px;
        }

        @media (max-width: 1280px) {

            max-width: 100vw;
            width: 100%;
        }

        // max-width: 1200px;
        padding: 0px 10px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .title {
            @media (max-width:480px) {
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            display: flex;
            justify-content: space-between;

            p {
                @media (max-width: 480px) {
                    text-align: center;
                }

                font-weight: 700;
                font-size: 30px;
                color: #919ba9;
            }

            button {
                @media (max-width: 480px) {
                    width: 100%;
                }

                width: 250px;
                display: grid;
                place-items: center;
                border-radius: 10px;
                background-color: #5D869E;
                height: 48px;
                color: white;
                font-weight: 700;
            }
        }

        .p-datatable-table {
           

            .p-datatable-thead>tr>th {
                background: #202A38 !important;
                color: white !important;
                font-weight: 700;
            }

            .p-datatable-thead>tr {
                &:nth-child(0) {
                    border-top-left-radius: 10px;
                }
            }

            .p-column-header-content {
                justify-content: center !important;
            }
        }

        .p-datatable-wrapper {
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            border: 1px solid #dee2e6;
        }

        .p-datatable .p-datatable-tbody>tr>td {
            text-align: center !important;
        }
    }
}

.actionTemplate {
    display: flex;
    justify-content: center;
    cursor: pointer;

    .ImgView_{

        &:hover {
            transform: scale(1.3);
            transition: transform .3s linear;
        }
    }
    
}