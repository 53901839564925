.Select {
  width: 100%;
//   height: 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  label{
    padding-left: 15px;
  }

  .select_drop {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    color: #020203;
    background: #f2f6fa;
    border: 2px solid #020203;
    border-radius: 15px;
    // padding: 0 15px;
    font-size: 12px;

    input{
        color: #020203;
    }
  }

  .p-dropdown-label {
    font-size: 12px;
    color: #000;
    
  }
}
