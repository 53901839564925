.Cookies{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .indide_modal{
        position: absolute;
        left: 10px;
        bottom: 10px;
        background: #fff;
        width: 300px;
        height: 250px;
        border-radius: 10px;
        padding: 15px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px; 

        @media (max-width: 768px) {
            position: absolute;
            left: unset;
            bottom: unset;
        }

        .img_cookies{
            position: absolute;
            top: -60px;
            left: calc(50% - 65px);
            width: 130px;
            height: 130px;
        }

        .block-text { 
            grid-area: 1 / 1 / 2 / 2; 
            display: flex;
            align-items: flex-end;

            .text_des{
                text-align: center;
                font-size: 12px;
                color: #8b8b8b;
                // color: #000;
            }
        }

        .block-bnt { 
            grid-area: 2 / 1 / 3 / 2; 
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .btn_cookies{
                width: 70%;
                height: 40px;
                color: #fff;
                border-radius: 10px;
                outline: none;
                font-size: 13px;
                
                &.accept_btn{
                    background: #067563;
                    
                }
                
                &.about_cookies{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    background: #af7406;

                }
            }
        } 
    }

}