.Button_ {
  width: 100%;
  height: 48px;
  border-radius: 15px;
  border: none;
  font-size: 13px;
  border: 2px solid #4b5563;
  cursor: pointer;
}

.btn_primary {
  background: #5d869e !important;
  color: white;

  &:hover{
    background: #3079a3;
  }
}

.btn_cancel {
  background: #202a38;
  color: white;

  &:hover{
    background: #070d14;
  }
}
.skeletonbutton
{
  width: 100%;
  height: 48px;
  border-radius: 15px;
  border: none;
  font-size: 13px;
  
}
