.Home_binn {
    width: 100%;
    padding-top: 66px;

    .cnt_imag {
        width: 100%;
        height: 370px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            height: 120px;
        }

        .img_ {
            width: 800px;

            @media (max-width: 768px) {
                width: 320px;
            }
        }

    }

    .Journal {
        min-height: calc(100vh - 270px);
        // width: 100%;
        //   background: #fff;
        padding: 40px;
        margin: 40px;
        border-radius: 24px;

        @media (max-width: 768px) {
            padding: 40px 8px 8px 8px;
            // padding: 8px;
            margin: 40px 8px 8px 8px;
        }

        .container {
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }

            width: min(95%, 1200px);
            // padding: 50px 0;
            // padding: 41px 82px 41px 82px;
            border-radius: 25px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 60% 35%;
            gap: 40px;

            .cover {
                @media (max-width: 480px) {
                    flex-direction: column;
                }

                display: flex;
                gap: 20px;
                
                .inside_cover{
                    display: flex;
                    gap: 20px;

                    @media (max-width: 768px) {
                        flex-direction: column;
                    }

                }

                p {
                    font-size: 16px;
                    text-align: justify;
                }

                .col_left {

                    .cnt_img__ {
                        width: 250px;
                        height: 300px;

                        .i_mage {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .cnt_rigth {
                    min-height: 230px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .current_reume {
                        // text-overflow: ellipsis;
                        // height: 235px;
                        // width: ;
                        // overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 10;
                        overflow: hidden;



                    }

                    p {
                        @media (max-width: 1024px) {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 16;
                            overflow: hidden;
                        }

                        @media (max-width: 480px) {
                            -webkit-line-clamp: 12;
                        }
                    }


                    .download {
                        @media (max-width: 1280px) {
                            max-width: 300px;
                            width: 100%;
                        }

                        @media (max-width: 480px) {
                            max-width: 100%;
                        }

                        height: 40px;
                        border-radius: 13px;
                        // background: #87000C;
                        // -webkit-box-shadow: 10px 10px 5px -4px rgba(27, 25, 42, 0.75);
                        // -moz-box-shadow: 10px 10px 5px -4px rgba(27, 25, 42, 0.75);
                        // box-shadow: 10px 10px 5px -4px rgba(27, 25, 42, 0.75);
                        border: none;
                        color: white;
                        text-transform: uppercase;
                        margin-top: 24px;
                        font-weight: 700;
                        text-decoration: none;
                        display: grid;
                        place-items: center;
                        cursor: pointer;

                        // &:hover {
                        //     color: #87000C;
                        //     background: #825b5b41;
                        //     transition: background .2s linear;
                        //     transition: color .2s linear;
                        // }
                    }
                }

            }

            .metrics {
                @media (max-width: 1280px) {
                    display: flex;
                    flex-direction: column-reverse;
                    padding-left: 0;
                    border-left: 0;

                }

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                border-left: 1px solid gainsboro;
                padding-left: 20px;

                .left {
                    @media (max-width: 1280px) {
                        gap: 10px;
                    }

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .title {
                        font-size: 24px;
                        margin: 0;
                    }

                    .in {
                        @media (max-width: 1280px) {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }

                    .icons {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        svg {
                            width: 25px;
                            height: 25px;
                            cursor: pointer;
                            transform: scale(1);
                            fill: gray;

                            &:hover {
                                transform: scale(1.1);
                                stroke: #87000C;
                                fill: #87000C;
                                transition: transform .2s linear;
                            }
                        }
                    }

                    .index {
                        display: flex;

                        p {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;


                    .cardGraph {
                        width: 100%;
                        height: 60px;
                        background-color: rgba(122, 122, 122, 0.203);
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        padding: 10px;
                        cursor: pointer;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.1);
                            transition: transform .2s linear;
                        }

                        p {
                            text-transform: uppercase;
                            margin: 0;
                            font-size: 12px;
                        }

                        .data {
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }

                    button {
                        text-align: right;
                        font-size: 12px;
                        font-style: italic;
                        color: gray;

                        // &:hover {
                        //     color: #87000C;
                        // }
                    }
                }

            }

            .articles_container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                grid-column: 1/3;
                border-top: 10px solid rgba(128, 128, 128, 0.306);
                padding-top: 50px;
                height: 100%;

                h1 {
                    font-size: 26px;
                    font-style: italic;
                    font-weight: 700;
                    color: #706a81;
                }

                .seeArticles {
                    @media (max-width: 768px) {
                        justify-content: center;
                    }

                    width: 100%;
                    display: flex;
                    justify-content: right;
                    border-bottom: 1px solid rgba(128, 128, 128, 0.306);
                    padding-bottom: 20px;

                    button {
                        @media (max-width: 480px) {
                            max-width: 100%;
                        }

                        height: 40px;
                        max-width: 300px;
                        width: 100%;
                        border-radius: 13px;
                        border: none;
                        color: white;
                        text-transform: uppercase;
                        margin-top: 24px;
                        font-weight: 700;
                        text-decoration: none;
                        display: grid;
                        place-items: center;
                        cursor: pointer;

                        // &:hover {
                        //     color: #87000C;
                        //     background: #825b5b41;
                        //     transition: background .2s linear;
                        //     transition: color .2s linear;
                        // }
                    }


                }

                .articles {
                    @media (max-width: 1280px) {
                        gap: 20px;
                        grid-template-columns: 55% 45%;
                    }

                    @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                    }

                    display: grid;
                    grid-template-columns: 65% 30%;
                    gap: 40px;
                    width: 100%;

                    .card_articule {
                        padding: 40px;
                        border-radius: 20px;

                        @media (max-width: 768px) {
                            padding: 40px 12px 12px;
                        }

                        .title_ar785{
                            margin-bottom: 15px;

                            @media (max-width: 768px) {
                                margin-bottom: 5px;
                            }
                        }

                        .article {
                            display: flex;
                            flex-direction: column;
                            text-align: justify;
                            gap: 5px;
                            border-bottom: 1px solid rgb(140, 132, 160);
                            margin-bottom: 20px;
                            padding-bottom: 15px;

                            @media (max-width: 768px) {
                                padding-bottom: 5px;
                            }

                            button {
                                text-align: left;
                                margin: 0;
                                font-style: italic;
                                font-weight: 400;
                                text-transform: lowercase;
                                cursor: pointer;
                                // color: rgb(140, 132, 160);

                                &::first-letter {
                                    text-transform: capitalize;
                                }

                                &:hover {
                                    text-decoration: underline;
                                    color: #87000C;
                                }
                            }

                            span {
                                opacity: 0.7;
                                margin: 0;

                            }

                            .authors {
                                font-size: 12.6px;
                                font-style: italic;
                            }

                            .date {
                                font-size: 14px;
                                color: rgba(128, 128, 128, 0.783);
                            }
                        }
                    }

                }

                .preVol {
                    @media (max-width: 480px) {
                        justify-content: center;
                    }

                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;

                    .cardVol {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        cursor: pointer;
                        transform: scale(1);

                        .cnt_image {
                            width: 195px;
                            height: 220px;
                            border-radius: 10px;

                            .i_mage_ {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                object-fit: cover;
                            }
                        }

                        @media (max-width: 768px) {
                            width: 300px;
                            align-items: center;
                        }

                        &:hover {
                            transform: scale(1.1);
                            transition: transform .3s linear;
                        }

                        img {
                            width: 140px;
                            height: 230px;
                            object-fit: cover;

                            @media (max-width: 768px) {
                                width: 230px;
                                height: 280px;
                                align-items: center;
                            }

                        }

                        .p {
                            font-size: 12px;
                            margin: 0;
                            font-style: italic;
                            // color: #706a81;
                        }

                        .title {
                            // color: #87000C;
                        }
                    }
                }

                .containerNumbers {
                    display: flex;
                    align-items: center;
                    margin: 80px 0 30px 0;

                    @media (max-width: 768px) {
                        margin-top: 20px;
                    }

                    .titleNumbers {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        gap: 30px;

                        .alert {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}