.cj-input-text-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    padding-left: 15px;
    font-size: 15px;
  }

  .cnt_input{
    position: relative;

      .input-pass {
        width: 100%;
        height: 48px;
        border-radius: 15px;
        background-color: #f2f6fa;
        border: 2px solid #020203;
        outline: none;
        padding: 0 15px;
        font-size: 12px;
    
        &::placeholder{
            font-size: 12px;
            opacity: 0.8;
        }
      }
    
      .input-icon-password {
        color: #d5d8e0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: unset;
        right: 20px;
        cursor: pointer;
        font-size: 12px;
        
      }
      .input-icon-password:hover {
        color: black;
      }
  }
  
}


