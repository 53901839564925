.cnt_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    label{
        padding-left: 15px;
        font-size: 15px;
    }

  ._input_ {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    background: #f2f6fa;
    border: 2px solid #020203;
    border-radius: 15px;
    padding: 0 15px;
    font-size: 12px;
    

    &::placeholder{
        font-size: 12px;
        opacity: 0.8;
    }
  }
 
}
