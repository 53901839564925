.Back{
    width: 50px;
    height: 50px;
    filter: drop-shadow(0px 0px 16px rgba(255, 255, 255, 0.25));
    background: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    .img_back{
        width: 21px;
        height: 21px;

    }
}