.LineTime {
  // width: 100%;
  border-radius: 24px;
  margin: 190px 40px 40px 40px;
  padding: 40px 40px 40px 40px;

  @media (max-width: 768px) {
    margin: 8px;
    margin-top: 160px;
    padding: 20px;
  }

  ._back_{
    position: absolute;
    top: 110px;
    left: 30px;

    @media (max-width: 768px) {
        top: 80px;
        left: 10px;
    }
  }

  .cnt_steps{
    border-radius: 16px;
    padding: 20px;
  }

  .description_s{
    width: 100%;
    margin-top: 116px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title_descrip{
        text-align: justify;
        line-height: 32px;
        font-weight: bold;
        font-size: 35px;
    }

    .block_descrip{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        h2{
            font-size: 20px;
            opacity: 0.7;
            font-weight: bold;
        }

        p{
            text-align: justify;
            font-size: 16px;
        }
    }
  }
}
