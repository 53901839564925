.Shedule {
  width: 100%;

  label{
    padding-left: 15px;
  }

  .calendar_ {
    width: 100%;
    height: 48px;
    background: #f2f6fa;
    border: 2px solid #020203;
    border-radius: 15px;
    font-size: 12px;

    input{
        border-radius: 15px;
        background: #f2f6fa;
        color: #020203;
        cursor: pointer;
    }
  }
}
