.Sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;


    .posted {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 2px solid rgb(140, 132, 160);
            gap: 10px;
            margin-top: 30px;

            img {
                width: 30px;
                height: 30px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
                font-style: italic;

                &:hover {
                    color: rgb(140, 132, 160);
                    letter-spacing: 1px;
                    transition: letter-spacing .3s linear;
                }
            }
        }

        .cnt_bank_ {

            .imgBank {
                display: flex;
                position: relative;

                .eye {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: grid;
                    place-items: center;
                    cursor: pointer;
                    background-color: #73639a85;
                    border-radius: 5px;

                    label {
                        font-size: 12px;
                        text-align: center;
                        font-style: italic;
                        color: #fff;
                    }

                    &:hover {
                        background-color: #73639aa2;

                        // svg {
                        // label{
                        //     width: 80px;
                        //     height: 80px;
                        //     transition: width .2s linear;
                        //     transition: height .2s linear;
                        // }
                    }
                }
            }
        }


        .editorial {
            width: 100%;
            border: 10px solid rgba(140, 132, 160, 0.235);
            margin-top: 30px;


            .comite {
                width: 100%;
                height: 40px;
                background: linear-gradient(to left, #8c84a0, #73639a);
                color: white;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    letter-spacing: 5px;
                    transition: letter-spacing .3s linear;
                }
            }

            .team {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 10px;

                display: flex;
                gap: 20px;

                label {
                    font-size: 12px;
                    text-align: center;
                    font-style: italic;
                }

                .cardTeam {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    flex-wrap: wrap;

                    .cnt_imagen_ {
                        width: 80px;
                        height: 80px;
                        border-radius: 5px;

                        img {
                            min-width: 80px;
                            min-height: 80px;
                        }

                        .Imag_g {
                            width: 100%;
                            height: 100%;
                            border-radius: 5px;
                            object-fit: cover;
                        }
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    font-size: 12px;
                    color: #9582c5;
                    font-weight: 700;

                    span {
                        opacity: 0.7;
                        font-weight: 400;
                    }
                }
            }

            .seeComite {
                display: flex;
                width: 100%;
                justify-content: right;

                button {
                    color: #73639a;
                    font-style: italic;
                    font-size: 13px;

                    &:hover {
                        color: #87000C;
                        letter-spacing: 2px;
                        transition: letter-spacing .3s linear;
                    }
                }
            }
        }
    }

    .events_container {
        display: flex;
        flex-direction: column;
        border: 10px solid rgba(140, 132, 160, 0.235);
        margin-top: 30px;

        .headerEvent {
            width: 100%;
            height: 25px;
            background: linear-gradient(to left, #8c84a0, #73639a);
            text-align: center;
            display: grid;
            place-items: center;
            color: white;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            font-size: 14px;
            font-weight: 600;
        }

        .events {
            display: flex;
            flex-direction: column;
            padding: 10px;
            gap: 20px;

            .nextText {
                text-align: center;
                font-size: 12px;
                font-style: italic;
            }

            .event {
                .date {
                    font-size: 12px;
                    color: #9582c5;
                    font-weight: 700;
                }

                .titleEvent {
                    font-size: 14px;
                    font-style: italic;
                    // color: #8c84a0;

                    &:hover {
                        color: #87000C;
                    }
                }
            }
        }
    }

    .dataRead {

        img {
            @media (max-width: 768px) {
                height: 300px;
            }

            @media (max-width: 480px) {
                height: 250px;
            }

            border: 0;
            width: 100%;
            height: 200px;
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;

            .left {
                display: flex;
                align-items: center;
                gap: 10px;

                strong {
                    margin: 0;
                }
            }

            a {
                color: #73639a;
                font-style: italic;
                font-size: 13px;

                &:hover {
                    color: #87000C;
                    letter-spacing: 1px;
                    transition: letter-spacing .3s linear;
                }
            }
        }

    }

    // .cnt_bank_{
    //     position: relative;
    //     pointer-events: none;


    // }
}