@font-face{
  font-family: 'Metropolis';
  /* src: local('Metropolis'), url(./assets/fonts/montserrat/Montserrat-Regular.ttf) format('truetype'); */
  src: local('Metropolis'), url(./assets/fonts/metropolis/Metropolis-Regular.otf);
}
body{
  font-family: "Metropolis", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
