.Log_ {
  width: 100%;
  // height: 100%;
  padding-top: 81px;

  .inside_log {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media (max-width: 1536px){
        height: unset;
    }

    .btn_return {
      position: absolute;
      top: 30px;
      left: 30px;

      @media (max-width: 768px) {
        top: 0px;
        left: 15px;
      }
    }

    .container {
      display: flex;
      // flex-wrap: wrap;
      justify-content: center;
      gap: 7%;

      @media (max-width: 1300px) {
        flex-wrap: wrap;
      }

      @media (max-width: 1536px){
        margin-top: 110px;
        gap: 50px;
      }

      

      .tamanio_cards {
        width: 400px;
        height: 617px;
        border-radius: 20px;
        background: #fff;
        filter: blur(0.5px);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 40px 25px;

        h1 {
          font-size: 30px;
          font-weight: 600;
        }

        .inside_card {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          position: relative;

          .instructions_pass{
            .desxr_{
              line-height: 11px;
              margin-top: 5px;
              font-size: 10px;
              padding: 0 15px;
            }
          }

          .cnt_selects {
            width: 100%;
            gap: 12px;
            display: flex;
          }

          .cnt_btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &.btn_second {
              width: 100%;
              display: flex;
              gap: 10px;

              .btn_primary {
                width: 100%;
              }
            }

            .btn_primary {
              width: 170px;
            }
          }

          .cnt-orcid {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            position: relative;

            .orcid_ {
              padding-left: 15px;
              font-size: 8px;
              color: #000;
              opacity: 0.5;

              .ur_l {
                opacity: 1;
              }
            }

            .ctn_mark{
                position: absolute;
                top: 46px;
                right: 9px;
                border-radius: 50%;
                background: #bf9035;
                cursor: pointer;

                @media (max-width: 768px) {
                    display: none;
                }

                &:hover{

                    .modal_mark{
                        display: flex;
                        position: absolute;
                        width: 309px;
                        height: 114px;
                        top: calc(50% - 57px);
                        right: 30px;
                        padding: 10px;
                        font-size: 11px;
                        background: #333333;
                        border-radius: 15px;
                        color: #fff;
                        cursor: default;
    
                        &::after{
                            content: "";
                            display: block;
                            border: 10px solid transparent;
                            border-left: 10px solid #333333;
                            position: absolute;
                            right: -20px;
                            top: calc(50% - 10px);
                        }
    
                    }
                }

                .modal_mark{
                    display: none;
                }


            }
          }
        }

        // @media (max-width: 768px) {
        //     height: 500px;
        // }

        &.three_card {
          .inside_three {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: 9px;

            .inside_card_three{
              gap: 20px;
            }
          }

          .th_ {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 10px;
          }
        }

        .layer_blur{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // backdrop-filter: blur(3px);
            border-radius: 18px;
          }
      }
    }

    .Ihave_Account {
      .link_account {
        font-size: 16px;
      }
    }


    &.hiddenMenu{
      display: none;
    }

  }

  .hiddenDiv{
    display: none;

    &.completedRegister{
      display: flex;
      height: 48vh;
      font-size: 20px;
      
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;

      label{
        font-weight: bold;
      }
      
    }
  }

  
}
