.InputSearch {
    position: relative;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #fff;

    input {
        height: 100%;
        width: 100%;
        border: none;
        font-size: 13px;
        outline: none;
        color: #000;
        border-radius: 20px;
        padding: 0 15px;
        border: 1px solid rgb(177, 173, 173);
        transition: border-color 0.2s;

        &::placeholder {
            font-family: "Roboto", sans-serif;
            font-size: 13px;
            color: #777;
            text-decoration: none solid rgb(0, 0, 0);
        }

        @media (max-width: 768px) {
            background: #f1f5f9;
        }

    }

    .cnt_lup {
        width: 30px;
        height: 30px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10px;
        top: calc(50% - 15px);
        cursor: pointer;

        .img_lup{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


}
