.VerificationCode
{
   
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #202A38;
    padding :0 10px;

    .inside_verificationCode{
      padding-bottom: 29px;
    }
    .inputContainer
    {
      margin-top: 53px;
      
    }
    .LoginCard 
    {
      .title-card
      {
          font-size: 20px!important;
      }
      .sub-title-card
      {
          font-size: 14px;
      }
    }
   
}