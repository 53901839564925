.Committee {
    display: grid;
    place-items: center;

    ._back_ {
        position: absolute;
        top: 110px;
        left: 30px;

        @media (max-width: 768px) {
            top: 80px;
            left: 10px;
        }
    }

    .container {
        max-width: 1200px;
        padding: 0px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        p {
            font-size: 30px;
            font-weight: 700;
            color: #9CA3AF;

        }

        .type {
            display: flex;
            gap: 30px;
            margin-bottom: 47px;

            button {
                color: #9CA3AF;
                font-size: 16px;
                padding: 2px 10px;
                font-weight: 700;
                border: 1px solid transparent;
            }

            .active {
                border: 1px solid #9CA3AF;
                border-radius: 10px;
                background: white;
                color: black;
            }
        }

        .containerData {
            @media (max-width:768px) {
                gap: 50px;
            }

            @media (max-width:480px) {
                gap: 20px;
                max-height: 100%;
            }

            display: flex;
            flex-wrap: wrap;
            gap: 90px;
            justify-content: center;
            // max-width: 500px;
            width: 100%;
            // max-height: 800px;
            overflow-y: auto;

            .data {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                p {
                    font-size: 16px;
                }

                .avatar {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    object-fit: cover;
                    // background-color: gray;
                }
            }
        }
    }
}