.logo-box {
  display: flex;
  justify-content: center;
  padding-top: 35px;

  .logo {
    width: 89px;
  }
}

.title-container {
  justify-content: center;
  color: white;
  padding-top: 48px;
  padding-bottom: 62px;

  .title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }

  .sub-title {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}

.code-box {
  display: flex;
  justify-content: center;

  .code-border {
    border: 1px solid rgba(228, 228, 228, 0.6);

    border-radius: 10px;

    .styles_react-code-input__CRulA > input {
      margin-left: 12px;
      font-weight: bold;
      font-size: 40px;
      caret-color: auto;

      background: #f2f6fa;
      border: 1px solid #e0e0e0;
      box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-family: "Roboto";

      @media screen and (max-width:420px) {
        margin-left: 4px;
      }
    }

    .styles_react-code-input-container__tpiKG {
      width: 100% !important;
      padding: 10px;
      
      @media screen and (max-width:420px) {
          width: 310px !important;
      }
    }
  }

  padding-bottom: 16px;
}

.code-box-red {
  display: flex;
  justify-content: center;

  .code-border-red {
    border-width: 1px;
    border-radius: 10px;

    .styles_react-code-input__CRulA > input {
      border-width: 1px;
      border-color: red;
      margin-left: 12px;
      border-radius: 10px;
      font-family: "Roboto";
      font-weight: bold;
      font-size: 40px;
      background: #f2f6fa;
      color: rgba(255, 0, 0, 100);
      caret-color: red;
      border-right: 1px solid red;
    }

    .styles_react-code-input-container__tpiKG {
      width: 100% !important;
      padding: 10px;
    }
  }

  padding-bottom: 52px;
}

.buttons-box {
    display: flex;
    gap:10px;

}

.bottom-link {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.styles_react-code-input__CRulA {
  background: white;
  border-radius: 10px;
  padding: 9px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonContainer-code
{
  display: flex;
  gap:10px;
  
}
