.Navbarr_ {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  padding: 0 30px;

  @media (max-width: 980px) {
    height: 65px;
    padding: 0 15px 0 10px;
  }

  .cnt_logo {
    height: 72px;
    width: 200px;

    @media (max-width: 980px) {
      width: 156px;
      height: 50px;
    }

    .img_logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .list_options {
    display: flex;
    align-items: center;

    &.hidden_element {
      display: none;
    }

    .cnt_ul {
      display: flex;
      gap: 30px;
      align-items: center;

      .searchNavbar{
        width: 260px;
      }

      .profile_a {
        display: flex;
        align-items: center;
        gap: 8px;

        position: relative;

        .cnt_profile {
          width: 50px;
          height: 50px;
          display: flex;
          font-weight: bold;
          font-size: 30px;
          background: transparent;
          border: 2px solid #fff;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;

          h1{
            font-weight: bold;
            font-size: 20px;
          }

        //   .img_profile {
        //     width: 100%;
        //     height: 100%;
        //     border-radius: 50%;
        //     object-fit: cover;
        //   }
        }

        .Arrow_donw {
          height: 20px;
            transition: all 0.2s;

          &.rotate_{
            transform: rotate(180deg);
            // transition: all 0.2s;
          }
        }

        .modal_ {
          position: absolute;
          bottom: -197px;
          right: -15px;
          width: 335px;
          height: 188px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          padding: 10px;

          .card_inside{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border-radius: 10px;
            padding-top: 25px;
            padding: 25px 7px 7px;

            .first_secc{
              display: flex;
              gap: 15px;

              .cnt_letter{
                width: 50px;
                height: 50px;
                display: flex;
                font-size: 30px;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                border-radius: 50%;
              }
  
              .cnt_dtas{
                display: flex;
                flex-direction: column;
                padding-top: 5px;
                gap: 8px;
                align-items: center;
  
                ._naeme{
                  width: 200px;
                  background: #fff;
                  border-radius: 5px;
                  border-top: 1px solid #000;
                  border-bottom: 1px solid #000;
                  font-size: 11px;
                  text-align: center;
                  padding: 5px 7px;
                }
  
                .email_l{
                  font-size: 10px;
                }
              }
            }


              .class_buttm{
                width: 230px;
                border-radius: 10px;
                border: 1px solid #000;
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                height: 35px;
              }
          }

          // .log_out {
          //   border: none;
          //   background: transparent;
          //   font-size: 12px;
          //   font-weight: bold;
          //   cursor: pointer;

          //   &:hover{
          //       color: #055a2f;
          //   }
          // }
        }
      }

      .navlinks_s {
        font-size: 18px;
        font-weight: bold;

        &:hover {
          color: rgb(20, 184, 166);
        }
      }
    }

    @media (max-width: 980px) {
      display: none;
    }
  }

  .cnt_menu {
    display: none;
    width: 40px;
    height: 32px;

    @media (max-width: 980px) {
      width: 30px;
      height: 20px;
      display: block;
    }

    .icon_white {
      width: 100%;
      height: 100%;
    }
  }
}
