.NewPassword
{
   
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #202A38;
    padding: 0 10px;
    .inputs-container
    {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 31px;
    }
    .rememberPassword
    {
        text-align: right;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
        margin-top: 15px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 40px;
    }
    .register
    {
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 11px;
        font-weight: 600;
        margin-top: 15px;
        cursor: pointer;
    }
    .LoginCard 
    {
      .title-card
      {
          font-size: 20px!important;
      }
      .sub-title-card
      {
          font-size: 14px;
      }
    }
}