.Login
{
   
    
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #202A38;
    padding: 100px 15px 15px 15px;
    position: relative;

    .back
    {
        margin: 30px;   
        position: absolute;
        top: 80px;
        left: 0;

        @media (max-width: 768px) {
            margin: 0 !important;
            left: 20px;
        }
    }

    .form_class{
        width: 400px;
    }
    .inputs-container
    {
        margin-top: 41px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media (min-width: 1024px) and (max-width: 1500px) {
            gap: 20px;
        }
    }
    .rememberPassword
    {
        text-align: right;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
        margin-top: 15px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 40px;
    }
    .register
    {
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 11px;
        font-weight: 600;
        margin-top: 15px;
        cursor: pointer;
    }
}