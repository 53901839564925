.Page404{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .cnt_oops{
        height: 537px;
        position: relative;

        .img_oops{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .btn_primary{
            background: #415673;
            font-size: 25px;
            position: absolute;
            bottom: calc(50% - 134px);
            // top: 66%;
            left: calc(50% - 100px);
            width: 200px;
        }
    }
}