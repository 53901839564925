.PrivacyPolicies{
    margin: 40px;
    margin-top: 195px;
    padding: 40px;
    border-radius: 24px;
    min-height: 100vh;
    position: relative;

    @media (max-width: 768px) {
        margin: 8px;
        margin-top: 145px;
        padding: 15px;
    }

    ._backlk_{
        position: absolute;
        left: 0px;
        top: -68px;
    }

    .inside_PrivacyPolicies{
        padding: 40px;
        border-radius: 12px;

        @media (max-width: 768px) {
            padding: 10px;
        }

        p{
            text-align: justify;
        }
    }
}