.Grid_a{
    display: grid;
    grid-template-columns: 65% 25%;
    gap: 120px;
    width: 100%;

    @media (max-width: 992px) {
        gap: 60px;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        gap: 40px;
        grid-template-columns: 55% 35%;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
    }

}